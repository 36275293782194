<template>
  <div v-if="fieldDefs != null && $root.$data.me.access.RB2">
    <b-container fluid>
      <b-row>
        <b-col>
          <h1 v-if="concept != null && !createdTrack">
            Track concept<span>: {{ concept.Title }} - {{ concept.Attribution }}</span>
          </h1>

          <h1 v-if="concept == false">Track concept not found, or not allowed to access</h1>

          <b-card v-if="createdTrack" header="Finished" class="mt-4">
            <page-snippet name="api-success-track" />
          </b-card>

          <b-card v-if="creating" header="Creating" class="mt-4">
            The track is being created.<b-spinner></b-spinner>
          </b-card>

          <a class="knowledgebase" href="https://support.the-source.eu/kb/c1/root-folder.aspx" target="_blank">Supportportal</a>
        </b-col>
      </b-row>

      <b-row v-if="concept && !creating && !createdTrack">
        <b-col>
          <b-card header="Asset upload" class="mt-4">
            <page-snippet v-if="assetFile === null" name="upload-asset-track" />
            
            <asset-upload
              v-show="assetFile === null"
              :caption="$t('Click here or drag and drop your audio here to upload (wav)')"
              :multiple="false"
              :isrcLookup="false"
              @add="setAsset"
              @update="setAsset"
            />
            <audio-player :src="audioFileUrl" v-if="audioFileUrl" :autoplay="false" />

            <b-alert 
              :show="showValidationErrors.value" 
              v-for="(error, index) in validationErrors['AssetFile']" 
              :key="index" 
              variant="danger" 
              v-html="error"
            />

            <b-button v-if="assetFile" @click="setAsset(null, false)">Remove uploaded file</b-button>

            <div v-if="assetFile">
              <b-alert :show="assetFile.isAudio === false" variant="warning">
                Uploaded file is not an audio file.
              </b-alert>

              <h4>{{ assetFile.name }}</h4>

              <template v-if="assetFile.info && assetFile.info.format == 'wav'">
                <span :class="{ 'font-weight-bold': true, 'text-success': assetFile.info.bitsPerSample >= 16, 'text-danger': assetFile.info.bitsPerSample < 16 }">
                  {{ assetFile.info.bitsPerSample }}bps
                </span>
                /
                <span :class="{ 'font-weight-bold': true, 'text-success': assetFile.info.sampleRate >= 44100, 'text-danger': assetFile.info.sampleRate < 44100 }">
                  {{ assetFile.info.sampleRate }}Hz
                </span>
                /
                <span :class="{ 'font-weight-bold': true, 'text-success': assetFile.info.channels == 2, 'text-danger': assetFile.info.channels != 2 }">
                  <template v-if="assetFile.info.channels == 1">
                    Mono
                  </template>
                  <template v-if="assetFile.info.channels == 2">
                    Stereo
                  </template>
                  <template v-if="![1,2].includes(assetFile.info.channels)">
                    {{ assetFile.info.channels }}ch
                  </template>
                </span>
                <br />
              </template>

              <template v-if="!assetFile.info || assetFile.info.format != 'wav'">
                <span class="text-danger" v-if="assetFile.info && assetFile.info.format">
                  Unsupported format: {{ assetFile.info.format }}
                </span>
              </template>

              <span v-if="assetFile.status != 'uploading'">{{ statusText[assetFile.status] }}</span>
              <b-progress v-if="assetFile.status == 'uploading'" :value="assetFile.uploadPercentage" :max="100" show-progress />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="concept && !creating && !createdTrack">
        <b-col>
          <track-concept
            v-if="concept"
            :track="concept"
            :fieldDefs="fieldDefs"
            :trackCount="1"
            :allowExisting="false"
            :errors="validationErrors"
            @errors="receiveErrors(null, $event)"
          />

          <b-alert v-if="showValidationErrors.value && Object.keys(validationErrors).length" show variant="danger" class="mt-4">
            {{ $t('Track contains errors, please correct before proceeding.') }}
          </b-alert>

          <b-button @click="create" class="mt-4">
            {{ $t('Create Track') }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="mt-5" v-if="$root.$data.debugInfoLevel > 0">
      <b-row>
        <b-col>
          <pre style="color: white;">{{ concept|jsonPretty }}</pre>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'
  import _ from 'lodash'

  export default {
    name: 'TrackConceptView',
    components: {
      'asset-upload': () => import('@/components/AssetUploadComponent'),
      'page-snippet': () => import('@/components/PageSnippet'),
      'track-concept': () => import('@/components/TrackConceptV2'),
      'audio-player': () => import('@/components/AudioPlayer'),
    },
    mixins: [
      ReleaseConceptHelper
    ],
    props: {
      id: String,
    },
    data () {
      return {
        concept: null,
        creating: false,
        createdTrack: null,
        showValidationErrors: { value: false },
        validationErrors: {},
        receivedErrors: {},
        settings: null,
        fieldDefs: this.$root.$data.fieldDefs.Track,
        audioFileUrl: null,
        statusText: {
          uploading: "Uploading",
          uploadComplete: "Upload ready",
          uploadError: "Upload error",
          invalidAudio: "Audio invalid",
        },
      }
    },
    provide () {
      return {
        showValidationErrors: this.showValidationErrors
      }
    },
    watch: {
      concept: {
        deep: true,
        handler (newValue, oldValue) {
          if (!this.concept) return;
          if (oldValue != null) {
            this.saveDebounced()
            this.validateConcept()
          }
        }
      }
    },
    methods: {
      save () {
        if (this.creating) return
        console.log('Saving concept')
        this.$http.put('release-concepts/' + this.id, { Json: JSON.stringify(this.concept) })
          .then(() => {
            console.log('Release saved')
          })
          .catch((error) => {
            console.log(error)
          })
      },
      saveDebounced: _.debounce(function () { this.save() }, 5000),
      receiveErrors (selected, errors) {
        Object.assign(this.receivedErrors, errors)
      },
      validateConcept () {
        let defs = this.fieldDefs
        let errors = {}
        let track = this.concept

        if (!this.assetFile || !this.assetFile.isAudio) {
          errors['AssetFile'] = ['A valid audio file is required.']
        }
        
        this.validate(track, defs, 'SourceTermsetID', errors)
        this.validate(track, defs, 'ISRC', errors)
        this.validate(track, defs, 'TitleLanguage', errors)
        this.validate(track, defs, 'LyricsLanguage', errors)
        this.validate(track, defs, 'Title', errors)
        this.validate(track, defs, 'VersionTitle', errors)
        this.validate(track, defs, 'Attribution', errors)
        this.validate(track, defs, 'Tags.MainGenre', errors)
        this.validate(track, defs, 'PLine', errors)
        this.validate(track, defs, 'CLine', errors)
        this.validate(track, defs, 'BPM', errors)
        this.validate(track, defs, 'WorkTitle', errors)
        this.validate(track, defs, 'Key', errors)
        this.validate(track, defs, 'ClassicalCatalogNr', errors)
        this.validate(track, defs, 'MovementTitle', errors)
        this.validate(track, defs, 'MovementNr', errors)
        this.validate(track, defs, 'Attributes.PreviewDuration', errors)
        this.validate(track, defs, 'Attributes.PreviewStartSeconds', errors)
        this.validateContributors(track, errors)
        this.validateOtherInfluences(track, errors)

        if (!track.Rights || !track.Rights[0].Territory) {
          errors['Territory'] = ['Territory is mandatory']
        }

        for (let [key, value] of Object.entries(this.receivedErrors)) {
          if (value.length > 0) {
            if (errors[key] === undefined) {
              errors[key] = []
            }
            errors[key] = errors[key].concat(value)
          }
        }

        this.validationErrors = errors
      },
      setAsset (file, save = false) {
        if (!file) {
          if (this.assetFile) {
            this.concept.AssetFiles = []
            this.audioFileUrl = null
          }
          return
        }
        this.$set(this.concept, 'AssetFiles', [
          { 
            TemporaryBlobID: file.sha,
            uploadPercentage: file.uploadPercentage,
            uploadComplete: file.uploadComplete,
            status: file.status,
            isAudio: file.isAudio,
            info: file.info,
          }
        ])

        // Show audio player when done
        if (file.uploadComplete && file.isAudio) {
          let reader = new FileReader();
          reader.onload = function(e) {
            this.audioFileUrl = e.target.result;
          }.bind(this)
          console.log(file)
          reader.readAsDataURL(file);
        }

        if (save) {
          this.save()
        }
      },
      create () {
        if (this.creating) return

        this.validateConcept()
        this.showValidationErrors.value = true
        if (Object.entries(this.validationErrors).length > 0) return

        this.creating = true
        this.$root.scroll()

        this.$http.put('release-concepts/' + this.id, { 
            Json: JSON.stringify(this.concept) ,
            Status: 'toCreate',
          }).then((response) => {
            this.$set(this, 'createdTrack', response.data)
            this.creating = false
          })
          .catch(() => {
            this.creating = false
            this.step += 1
          })
      },
    },
    computed: {
      assetFile () {
        if (!this.concept.AssetFiles || this.concept.AssetFiles.length != 1) return null
        return this.concept.AssetFiles[0] 
      },
    },
    mounted () {
      this.$http.get('release-concepts/' + this.id)
        .then(response => {
          if (response.data == false) {
            this.concept = false
            return
          }

          if (response.data.Status == 'apiAccepted') {
            this.createdTrack = true
          }

          const concept = JSON.parse(response.data.Json)

          if (!concept.Rights) {
            const territory = response.data.DefaultTerritory
            concept.Rights = [
                {
                  Territory: territory != 'none' ? territory : '',
                  AllowAdvertising: this.fieldDefs['Rights.AllowAdvertising'].DefaultValue,
                  AllowSubscription: this.fieldDefs['Rights.AllowSubscription'].DefaultValue,
                  AllowTransaction: this.fieldDefs['Rights.AllowTransaction'].DefaultValue,
                }
            ]
          }

          if (!concept.Tags) {
            let tags = [ { Type: 'Category', Value: 'Audio' } ]

            for (const [field, val] of Object.entries(this.fieldDefs)) {
              if (!field.startsWith('Tags.')) continue
              if (val.DefaultValue === null) continue

              tags.push({ Type: val.JsonField, Value: val.DefaultValue})
            }
            concept.Tags = tags
          }

          if (response.data.Status == 'apiCreated') {
            this.createdTrack = true;
          }

          this.$set(this, 'concept', concept)
          this.settings = response.data.Settings
        })
        .catch((error) => {
          console.log(error)
        })
    },
  }
</script>

<style lang="scss" scoped>
  a.knowledgebase {
    position: absolute;
    right: 20px;
    top: 0;
  }

  ul.wizard-steps {
    margin-left: 0;
    padding: 0;

    li {
      &::before {
        font-weight: normal;  
        content: '>> ';
      }
      &.active {
        font-weight: bold;
      }
      margin-right: 0.5em;
      display: inline-block;
      list-style-type: none;
    }
  }
</style>
