var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fieldDefs != null && _vm.$root.$data.me.access.RB2
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _vm.concept != null && !_vm.createdTrack
                        ? _c("h1", [
                            _vm._v(" Track concept"),
                            _c("span", [
                              _vm._v(
                                ": " +
                                  _vm._s(_vm.concept.Title) +
                                  " - " +
                                  _vm._s(_vm.concept.Attribution)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.concept == false
                        ? _c("h1", [
                            _vm._v(
                              "Track concept not found, or not allowed to access"
                            )
                          ])
                        : _vm._e(),
                      _vm.createdTrack
                        ? _c(
                            "b-card",
                            {
                              staticClass: "mt-4",
                              attrs: { header: "Finished" }
                            },
                            [
                              _c("page-snippet", {
                                attrs: { name: "api-success-track" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.creating
                        ? _c(
                            "b-card",
                            {
                              staticClass: "mt-4",
                              attrs: { header: "Creating" }
                            },
                            [
                              _vm._v(" The track is being created."),
                              _c("b-spinner")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "knowledgebase",
                          attrs: {
                            href:
                              "https://support.the-source.eu/kb/c1/root-folder.aspx",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Supportportal")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.concept && !_vm.creating && !_vm.createdTrack
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "mt-4",
                              attrs: { header: "Asset upload" }
                            },
                            [
                              _vm.assetFile === null
                                ? _c("page-snippet", {
                                    attrs: { name: "upload-asset-track" }
                                  })
                                : _vm._e(),
                              _c("asset-upload", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.assetFile === null,
                                    expression: "assetFile === null"
                                  }
                                ],
                                attrs: {
                                  caption: _vm.$t(
                                    "Click here or drag and drop your audio here to upload (wav)"
                                  ),
                                  multiple: false,
                                  isrcLookup: false
                                },
                                on: { add: _vm.setAsset, update: _vm.setAsset }
                              }),
                              _vm.audioFileUrl
                                ? _c("audio-player", {
                                    attrs: {
                                      src: _vm.audioFileUrl,
                                      autoplay: false
                                    }
                                  })
                                : _vm._e(),
                              _vm._l(
                                _vm.validationErrors["AssetFile"],
                                function(error, index) {
                                  return _c("b-alert", {
                                    key: index,
                                    attrs: {
                                      show: _vm.showValidationErrors.value,
                                      variant: "danger"
                                    },
                                    domProps: { innerHTML: _vm._s(error) }
                                  })
                                }
                              ),
                              _vm.assetFile
                                ? _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.setAsset(null, false)
                                        }
                                      }
                                    },
                                    [_vm._v("Remove uploaded file")]
                                  )
                                : _vm._e(),
                              _vm.assetFile
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          attrs: {
                                            show:
                                              _vm.assetFile.isAudio === false,
                                            variant: "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Uploaded file is not an audio file. "
                                          )
                                        ]
                                      ),
                                      _c("h4", [
                                        _vm._v(_vm._s(_vm.assetFile.name))
                                      ]),
                                      _vm.assetFile.info &&
                                      _vm.assetFile.info.format == "wav"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    _vm.assetFile.info
                                                      .bitsPerSample >= 16,
                                                  "text-danger":
                                                    _vm.assetFile.info
                                                      .bitsPerSample < 16
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.assetFile.info
                                                        .bitsPerSample
                                                    ) +
                                                    "bps "
                                                )
                                              ]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    _vm.assetFile.info
                                                      .sampleRate >= 44100,
                                                  "text-danger":
                                                    _vm.assetFile.info
                                                      .sampleRate < 44100
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.assetFile.info
                                                        .sampleRate
                                                    ) +
                                                    "Hz "
                                                )
                                              ]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    _vm.assetFile.info
                                                      .channels == 2,
                                                  "text-danger":
                                                    _vm.assetFile.info
                                                      .channels != 2
                                                }
                                              },
                                              [
                                                _vm.assetFile.info.channels == 1
                                                  ? [_vm._v(" Mono ")]
                                                  : _vm._e(),
                                                _vm.assetFile.info.channels == 2
                                                  ? [_vm._v(" Stereo ")]
                                                  : _vm._e(),
                                                ![1, 2].includes(
                                                  _vm.assetFile.info.channels
                                                )
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.assetFile.info
                                                              .channels
                                                          ) +
                                                          "ch "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _c("br")
                                          ]
                                        : _vm._e(),
                                      !_vm.assetFile.info ||
                                      _vm.assetFile.info.format != "wav"
                                        ? [
                                            _vm.assetFile.info &&
                                            _vm.assetFile.info.format
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Unsupported format: " +
                                                        _vm._s(
                                                          _vm.assetFile.info
                                                            .format
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      _vm.assetFile.status != "uploading"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.statusText[
                                                  _vm.assetFile.status
                                                ]
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.assetFile.status == "uploading"
                                        ? _c("b-progress", {
                                            attrs: {
                                              value:
                                                _vm.assetFile.uploadPercentage,
                                              max: 100,
                                              "show-progress": ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.concept && !_vm.creating && !_vm.createdTrack
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _vm.concept
                            ? _c("track-concept", {
                                attrs: {
                                  track: _vm.concept,
                                  fieldDefs: _vm.fieldDefs,
                                  trackCount: 1,
                                  allowExisting: false,
                                  errors: _vm.validationErrors
                                },
                                on: {
                                  errors: function($event) {
                                    return _vm.receiveErrors(null, $event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.showValidationErrors.value &&
                          Object.keys(_vm.validationErrors).length
                            ? _c(
                                "b-alert",
                                {
                                  staticClass: "mt-4",
                                  attrs: { show: "", variant: "danger" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "Track contains errors, please correct before proceeding."
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            { staticClass: "mt-4", on: { click: _vm.create } },
                            [_vm._v(" " + _vm._s(_vm.$t("Create Track")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.$root.$data.debugInfoLevel > 0
            ? _c(
                "b-container",
                { staticClass: "mt-5", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("pre", { staticStyle: { color: "white" } }, [
                          _vm._v(_vm._s(_vm._f("jsonPretty")(_vm.concept)))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }